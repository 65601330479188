import React from "react"
// import { Link } from "gatsby"
import MKL from '../images/mkl.jpeg'
import Layout from "../components/layout"
import SEO from "../components/seo"

const About = () => (
  <Layout bgcolor="#99dbdf">
    <SEO title="About MKL Public Relations" bgcolor="#ffcc00" />
    <h1>about MKL</h1>
    <img src={MKL} style={{float: 'right', maxWidth: 250, margin: '0 0 20px 20px', clipPath: 'circle(90px at center)'}} alt="Mary Kay Lazarus" />
    <p>Established in 1986, <strong>MKL Public Relations</strong> has built its reputation on compelling public relations campaigns based on keen, strategic thinking. We thrive on hard work, informed planning, creativity and long-term client relations. Our focus on articulating the newsworthiness of a client's story while working aggressively to tell that story – whether it's engaging a single reporter at a specific news organization or informing many reporters and editors nationally – has established media relations as one of our core competencies. Agency expertise in media training and crisis communications ensures that demanding requests and challenging situations entrusted to us are met with thoughtful, persuasive responsiveness.</p>
    <p><strong>MKL Public Relations</strong> has a shared commitment to protect and promote the client mission, brand and messaging through an advanced level of accuracy, refinement and diligence. From public awareness programs designed to inform and build support to branding campaigns, special events and crisis communications work, we have solid relationships with – and extensive experience in presenting stories to – the news media. MKL Public Relations combines entrepreneurial energy and talent with experience and credibility. Alert to every opportunity to tell our client's story, we immerse ourselves in the client’s mission with an emotional investment in the success of our results. We customize our services to meet the unique needs and goals of each client and to earn their trust while providing results that go beyond their expectations.</p>
    <p><strong>MKL Public Relations</strong> has generated cover stories and features in a spectrum of press ranging from the New York Times, the Wall Street Journal, Entrepreneur magazine, and l’Express to People magazine, the Today Show, forbes.com and cbsmarketwatch.com. We have a track record in community relations, initiating and orchestrating numerous events including award-winning projects for the city of West Valley, Utah and for the statewide Schindler's List High school student project. We have successfully completed crisis communications work for the Olympic Hill Shopping Center litigation, Westinghouse Corporation and the International Eco-Challenge created by Mark Burnett, executive producer of the Survivor television series. Turn-away crowds marked special events we planned and publicized for Mikhail Gorbachev, Stephen Hawking, ShimonPeres, and Lech Walesa. Our publicity campaign for the Christmas Box brought the book to number one on the New York Times best seller lists, both hardcover and paperback.</p>
  </Layout>
)

export default About
